<template>
  <v-form v-model="valid" ref="form">
    <v-layout row wrap justify-center>
      <v-flex lg6 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Fornecedor"
            name="nome"
            placeholder="Fornecedor"
            v-model="fornecedor.nome"
            :counter="80"
            :rules="nameRules"
            :readonly="view"
            required
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Telefone 1"
            name="telefone"
            placeholder="(##)#####-####"
            v-model="fornecedor.telefone1"
            :counter="14"
            :rules="phoneRules"
            v-mask="'(##)#####-####'"
            :readonly="view"
            required
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Telefone 2"
            name="telefone"
            placeholder="(##)#####-####"
            v-model="fornecedor.telefone2"
            :counter="14"
            :rules="phoneRules"
            v-mask="'(##)#####-####'"
            :readonly="view"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="E-mail"
            name="email"
            placeholder="E-mail"
            :rules="emailRules"
            v-model="fornecedor.email"
            :counter="80"
            :readonly="view"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="CNPJ"
            name="cnpj"
            placeholder="##.###.###/####-##"
            v-model="fornecedor.cnpj"
            :counter="18"
            :rules="cnpjRules"
            v-mask="'##.###.###/####-##'"
            :readonly="view"
            required
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Cep"
            name="cep"
            placeholder="Cep"
            v-model="fornecedor.cep"
            @blur="buscaCep"
            :counter="8"
            :rules="cepRules"
            :readonly="view"
          ></v-text-field>
        </div>
      </v-flex>
      <v-flex lg6 sm12 xs12>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Rua"
            name="rua"
            placeholder="Rua"
            v-model="fornecedor.logradouro"
            :counter="80"
            :readonly="view"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Número"
            name="numero"
            placeholder="Número"
            v-model="fornecedor.numero"
            :readonly="view"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Bairro"
            name="bairro"
            placeholder="Bairro"
            v-model="fornecedor.bairro"
            :counter="80"
            :readonly="view"
          ></v-text-field>
        </div>
        <div class="d-flex">
          <v-text-field
            class="mr-2"
            label="Cidade"
            name="cidade"
            placeholder="Cidade"
            v-model="fornecedor.cidade"
            :readonly="view"
          ></v-text-field>
          <v-text-field
            class="mr-2"
            label="Estado"
            name="estado"
            placeholder="Estado"
            v-model="fornecedor.estado"
            :readonly="view"
          ></v-text-field>
        </div>
      </v-flex>
      <div class="form-btn">
        <v-btn v-if="!this.view" outline @click="submit" color="primary">Salvar</v-btn>
        <v-btn v-if="!this.view" outline @click="clear">Limpar</v-btn>
        <router-link :to="{ name: 'Fornecedor' }" class="nounderline">
          <v-btn outline>Voltar</v-btn>
        </router-link>
      </div>
    </v-layout>
  </v-form>
</template>
<script>
import { ServicoFornecedor } from "../../servicos/servicoFornecedor"
import Fornecedor from "../../domain/fornecedor/Fornecedor.js"
import { ServicoConsultaCep } from "../../servicos/servicoConsultaCep"
const servicoFornecedor = new ServicoFornecedor()
const servicoConsultaCep = new ServicoConsultaCep()
export default {
  data: () => ({
    id: null,
    view: false,
    fornecedor: new Fornecedor(),
    nameRules: [
      v => !!v || "Nome é obrigatório",
      v => v.length <= 80 || "Nome não deve possuir mais que 80 caracteres",
      v => v.length >= 3 || "Nome não deve possuir menos que 3 caracteres"
    ],
    emailRules: [
      v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || v == null || v.length == 0 || "E-mail inválido"
    ],
    phoneRules: [v => v == null || v.length >= 10 || v.length == 0 || "Telefone deve possuir 10 ou 11 dígitos"],
    cnpjRules: [
      v => v == null || v.length >= 18 || v.length == 0 || "CNPJ deve possuir 18 dígitos",
      v => v == null || v.length <= 18 || v.length == 0 || "CNPJ deve possuir 18 dígitos"
    ],
    cepRules: [
      v => v == null || v.length >= 8 || v.length == 0 || "Cep deve possuir 8 dígitos",
      v => v == null || v.length <= 8 || v.length == 0 || "Cep deve possuir 8 dígitos"
    ],
    valid: true
  }),
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.fornecedor.id = this.id ? parseInt(this.id) : 0
        servicoFornecedor.salvarFornecedor(this.fornecedor).then(
          res => {
            if (res.status === 200) {
              this.$router.push({ name: "Fornecedor" })
            }
          },
      // eslint-disable-next-line
          err => console.log(err)
        )
      }
    },
    clear() {
      this.fornecedor = new Fornecedor()
      this.$refs.form.resetValidation()
    },
    closeDialog() {
      this.$parent.isActive = false
    },
    buscaCep() {
      var cep = this.fornecedor.cep
      servicoConsultaCep.buscaCep(cep).then(res => {
        if (res.status === 200) {
          this.fornecedor.logradouro = res.data.logradouro
          this.fornecedor.bairro = res.data.bairro
          this.fornecedor.cidade = res.data.localidade
          this.fornecedor.estado = res.data.uf
        }
      })
    }
  },
  created() {
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id

      if (this.$route.name === "view-fornecedor") {
        this.view = true
      }
    }
    if (this.id) {
      servicoFornecedor.buscarPorId(this.id).then(res => {
        this.fornecedor = res.data
      })
    }
  }
}
</script>
<style>
a.nounderline:link {
  text-decoration: none;
}
</style>
