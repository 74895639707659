<template>
  <div id="pageCadastroFornecedor">
    <v-container grid-list-x2 fluid>
      <v-layout row wrap justify-center>
        <v-flex lg11 sm12 xs12>
          <v-card>
            <v-toolbar color="pink" dark flat dense cad>
              <v-toolbar-title v-if="this.id && !this.view" class="subheading">Editar fornecedor</v-toolbar-title>
              <v-toolbar-title v-if="this.view" class="subheading">Fornecedor</v-toolbar-title>
              <v-toolbar-title v-if="!this.id && !this.view" class="subheading">Novo fornecedor</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="">
              <fornecedor-form></fornecedor-form>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import FornecedorForm from "@/components/fornecedor/formFornecedor"
export default {
  components: {
    FornecedorForm
  },
  data: () => ({
    id: null
  }),
  created() {
    if (this.$route && this.$route.params) {
      this.id = this.$route.params.id
      if (this.$route.name === "view-fornecedor") {
        this.view = true
      }
    }
  }
}
</script>
